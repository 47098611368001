.n {
  list-style-type: none;
}


.viewCard {
  min-height: 400px;
  margin-top: 20px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.code-container {
  position: relative;
}

.code-container pre {
  /* max-height: 400px; */
  /* overflow-y: auto; */
  background-color: #2d2d2d;
  color: #ffffff;
  padding: 10px;
  border-radius: 5px;
  font-size: 14px;
  font-family: "Courier New", Courier, monospace;
}


.code-buttons {
  display: none;
  position: absolute;
  top: 5px;
  right: 10px;
  z-index: 10;
}

.code-container:hover .code-buttons {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.code-buttons button {
  background-color: #333;
  color: #fff;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 12px;
  border-radius: 3px;
}

.code-buttons button:hover {
  background-color: #555;
}

.copied-text {
  color: green;
  font-weight: bold;
  text-align: center;
  margin-top: 10px;
  animation: fadeOut 4s forwards;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}



/* tags----------------------------------- */


.tags-container {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  padding: 10px;
}

.tag-button {
  background-color: #eef0f2;
  border: none;
  border-radius: 5px;
  padding: 3px 5px;
  transition: transform 0.2s ease, background-color 0.3s ease;
  box-shadow: 1px 2px 3px #6e7a9b;
}

.tag-button:hover {
  transform: translateY(-0.5px);
}

.tag-link {
  text-decoration: none;
  color: #173a9b;
  font-size: 14px;
  font-family: Arial, sans-serif;
}

.tag-link:hover {
  text-decoration: none;
}