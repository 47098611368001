.hamburger-button {
    display: block;
    width: 30px;
    height: 20px;
    right: 60px;
    margin-left: 270px;
    margin-top: 15px;
    cursor: pointer;
    position: relative;
    transition: transform 0.2s;
    z-index: 100%;
    top: 15px;
    margin-left: 230px;
}

.bar {
    background: white;
    width: 100%;
    height: 4px;
    margin: 5px 0;
    right: 90px;
    transition: all 0.3s ease-in;
}


@media (max-width: 375px) {
    .navigation {
        background-color: #e90c0c;
        width: 100%;
        right: 10px;
        transform: translateX(0);
        transition: right 0.5s;
        position: fixed;
        top: -100%;
    }
}

.navigation {
    position: fixed;
    right: 60px;
    width: 200px;
    height: 14%;
    margin-top: 50px;
    background-color: #173a9b;
    transform: translateY(-2000%);
    transition: transform 0.5s;
    top: 0;
    z-index: 1;
    border-radius: 10px;

}

.navigation ul {
    list-style: none;
    padding: 0;
    text-align: right;
}

.navigation ul li {
    color: #fff;
    font-size: 28px;
    padding: 10px 10px;
}

.navigation.active {
    right: 60px;
    top: 0;

}

.navigation.active {
    transform: translateY(0);
    height: 135px;
    margin-right: -50px;

}

.toggle-button {
    position: fixed;
    top: 40px;
    right: 60px;
    background: #173a9b;
    color: white;
    border: none;
    padding: 10px;
    margin-right: -200px;
    cursor: pointer;
}

@media (min-width: 344px) and (max-width: 1280px) {
    .hamburger-button {
        position: absolute;
        top: 10px;
        right: 60px;
        transform: translateY(0);
        top: 18px;
        margin-right: -40px;
    }
}

@media (min-width: 360px) and (max-width: 760px) {
    .navigation {
        background-color: #173a9b;
        color: white;
        height: 140px;
        top: -2%;
        z-index: 1;
        margin-right: -45px;

    }
}

@media (min-width: 768px) and (max-width: 1280px) {
    .navigation {
        background-color: #173a9b;
        color: white;
        height: 140px;
        top: -2%;
        z-index: 1;
        margin-right: -45px;

    }
}

@media (min-width: 1024px) and (max-width: 1280px) {
    .navigation {
        background-color: #173a9b;
        color: white;
        height: 140px;
        top: -2%;
        z-index: 1;
        margin-right: -45px;

    }
}

@media (min-width: 1300px) {
    .hamburger-button {
        position: absolute;
        top: 10px;
        right: 60px;
        transform: translateY(0);
        margin-right: -20px;
    }
}