.abo {
    height: 600px;
    background-image: url(../../../public/images/motivationAbout.png);
    background-size: 100% 100%;
    border-radius: 20px;

}

.abo1 {
    min-height: 600px;
    border-radius: 20px;
    background-image: linear-gradient(to right, white, #97a7e3);
}

.sidebar_span {
    font-size: 22px;
    font-family: Arial, Helvetica, sans-serif
}