.box {
    min-height: 400px;
    box-shadow: -1px -1px 5px rgb(97, 121, 221);
    margin-top: 50px;
   
}

.box1,
.box2 {
    min-height: 30px;
    background: #173a9b;
    color: white;
    margin-top: 20px;
    font-size: 20px;
    text-align: center;
    line-height: 40px;
}

.box2 {
    line-height: 31px;
}