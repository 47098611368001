#read {
    min-height: 70px;
    /* width: 800px; */
    border: 1px solid #c9c9c9;
    margin-bottom: 20px;
    line-height: 70px;
    border-radius: 5px;
    box-shadow: -1px -1px 10px 1px rgb(109, 131, 197);
    text-align: center;
    color: #8a8787;
}

#read1 {
    display: none;
    /* width: 600px; */
    min-height: 100px;

}