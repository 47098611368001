.not-found-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 80vh;

    font-family: Arial, sans-serif;
}

.not-found-content {
    text-align: center;
    padding: 20px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.not-found-content h1 {
    font-size: 100px;
    color: #093abf;
    margin: 0;
}

.not-found-content p {
    font-size: 18px;
    color: #070707d9;
    margin: 10px 0;
}

.go-home-button {
    padding: 10px 20px;
    background-color: #173a9b;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
    font-size: 16px;
}

.go-home-button:hover {
    background-color: #0056b3;
}

@keyframes slide {
    0% {
        transform: translateX(0);
    }

    25% {
        transform: translateX(-10px);
    }

    50% {
        transform: translateX(0);
    }

    75% {
        transform: translateX(10px);
    }

    100% {
        transform: translateX(0);
    }
}

.animated-icon {
    display: inline-block;
    animation: slide 2s infinite ease-in-out;
}