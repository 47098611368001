.heading {
    color: red;
    position: relative;
    text-align: center;
    font-size: 25px;
    margin: 0 30%;
    font-family: 'Lato', sans-serif;
    font-style: underline;
}

.search {
    position: absolute;
    top: 4%;
    right: 50px;
    transform: translate(-50%, -50%);
    background: white;
    height: 40px;
    border-radius: 17px;
    padding: 3px;
}

.search:hover>.search-txt {
    width: 180px;
    padding: 0 10px;
    color: black;
    font-family: 'Pangolin', cursive;
}



.search-btn {
    color: rgb(4, 65, 230);
    float: right;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background: #ABB2B9;
    display: flex;
    justify-content: center;
    align-items: center;
}

.search-txt {
    border: none;
    background: none;
    outline: none;
    float: left;
    padding: 0;
    color: black;
    font-size: 16px;
    transition: ease-in 0.4s;
    line-height: 40px;
    transform: translateY(0px);
    width: 110px;
    font-family: 'Pangolin', cursive;
}

.fas fa-search {
    font-weight: 200;
    font-size: 40px;
}




@media (min-width: 375px) {
    .search {
        position: absolute;
        top: 5%;
        right: 130px;
        transform: translateX(71px);
        background: white;
        height: 35px;
        border-radius: 17px;
        padding: 3px;
        display: flex;
        align-items: center;
    }

    .search-txt {
        border: none;
        background: none;
        outline: none;
        padding: 10px;
        color: black;
        font-size: 16px;
        transition: width 0.4s ease-in;
        line-height: 35px;
        width: 75px;
        font-family: 'Pangolin', cursive;
        cursor: pointer;
    }

    .search-txt:focus {
        width: 150px;
        cursor: text;
    }
}

@media (min-width: 414px) {
    .search {
        position: absolute;
        top: 4%;
        right: 130px;
        transform: translateX(71px);
        background: white;
        height: 35px;
        border-radius: 17px;
        padding: 3px;
        display: flex;
        align-items: center;
    }

    .search-txt {
        border: none;
        background: none;
        outline: none;
        padding: 10px;
        color: black;
        font-size: 16px;
        transition: width 0.4s ease-in;
        line-height: 35px;
        width: 75px;
        font-family: 'Pangolin', cursive;
        cursor: pointer;
    }

    .search-txt:focus {
        width: 150px;
        cursor: text;
    }
}

@media (min-width: 390px) {
    .search {
        position: absolute;
        top: 4%;
        right: 130px;
        transform: translateX(71px);
        background: white;
        height: 35px;
        border-radius: 17px;
        padding: 3px;
        display: flex;
        align-items: center;
    }

    .search-txt {
        border: none;
        background: none;
        outline: none;
        padding: 10px;
        color: black;
        font-size: 16px;
        transition: width 0.4s ease-in;
        line-height: 35px;
        width: 75px;
        font-family: 'Pangolin', cursive;
        cursor: pointer;
    }

    .search-txt:focus {
        width: 150px;
        cursor: text;
    }
}

@media (min-width: 360px) {
    .search {
        position: absolute;
        top: 4%;
        right: 130px;
        transform: translateX(71px);
        background: white;
        height: 35px;
        border-radius: 17px;
        padding: 3px;
        display: flex;
        align-items: center;
    }

    .search-txt {
        border: none;
        background: none;
        outline: none;
        padding: 10px;
        color: black;
        font-size: 16px;
        transition: width 0.4s ease-in;
        line-height: 35px;
        width: 75px;
        font-family: 'Pangolin', cursive;
        cursor: pointer;
    }

    .search-txt:focus {
        width: 150px;
        cursor: text;
    }
}

@media (min-width: 768px) {
    .search {
        position: absolute;
        top: 3%;
        right: 130px;
        transform: translateX(71px);
        background: white;
        height: 35px;
        border-radius: 17px;
        padding: 3px;
        display: flex;
        align-items: center;
    }

    .search-txt {
        border: none;
        background: none;
        outline: none;
        padding: 10px;
        color: black;
        font-size: 16px;
        transition: width 0.4s ease-in;
        line-height: 35px;
        width: 75px;
        font-family: 'Pangolin', cursive;
        cursor: pointer;
    }

    .search-txt:focus {
        width: 150px;
        cursor: text;
    }
}

@media (min-width: 820px) {
    .search {
        position: absolute;
        top: 35px;
        right: 130px;
        transform: translateX(71px);
        background: white;
        height: 35px;
        border-radius: 17px;
        padding: 3px;
        display: flex;
        align-items: center;
    }

    .search-txt {
        border: none;
        background: none;
        outline: none;
        padding: 10px;
        color: black;
        font-size: 16px;
        transition: width 0.4s ease-in;
        line-height: 35px;
        width: 75px;
        font-family: 'Pangolin', cursive;
        cursor: pointer;
    }

    .search-txt:focus {
        width: 150px;
        cursor: text;
    }
}

@media (min-width: 853px) {
    .search {
        position: absolute;
        top: 35px;
        right: 130px;
        transform: translateX(71px);
        background: white;
        height: 35px;
        border-radius: 17px;
        padding: 3px;
        display: flex;
        align-items: center;
    }

    .search-txt {
        border: none;
        background: none;
        outline: none;
        padding: 10px;
        color: black;
        font-size: 16px;
        transition: width 0.4s ease-in;
        line-height: 35px;
        width: 100px;
        font-family: 'Pangolin', cursive;
        cursor: pointer;
    }

    .search-txt:focus {
        width: 150px;
        cursor: text;
    }
}

@media (min-width: 1024px) {
    .search {
        position: absolute;
        top: 35px;
        right: 130px;
        transform: translateX(71px);
        background: white;
        height: 35px;
        border-radius: 17px;
        padding: 3px;
        display: flex;
        align-items: center;
    }

    .search-txt {
        border: none;
        background: none;
        outline: none;
        padding: 10px;
        color: black;
        font-size: 16px;
        transition: width 0.4s ease-in;
        line-height: 35px;
        width: 130px;
        font-family: 'Pangolin', cursive;
        cursor: pointer;
    }

    .search-txt:focus {
        width: 150px;
        cursor: text;
    }
}

@media (min-width: 344px) {
    .search {
        position: absolute;
        top: 35px;
        right: 130px;
        transform: translateX(71px);
        background: white;
        height: 35px;
        border-radius: 17px;
        padding: 3px;
        display: flex;
        align-items: center;
    }

    .search-txt {
        border: none;
        background: none;
        outline: none;
        padding: 10px;
        color: black;
        font-size: 16px;
        transition: width 0.4s ease-in;
        line-height: 35px;
        width: 60px;
        font-family: 'Pangolin', cursive;
        cursor: pointer;
    }

    .search-txt:focus {
        width: 150px;
        cursor: text;
    }
}



@media (min-width: 1300px) {
    .search {
        position: absolute;
        top: 25px;
        right: 130px;
        transform: translateX(-1px);
        background: white;
        height: 35px;
        border-radius: 17px;
        padding: 3px;
        display: flex;
        align-items: center;
    }

    .search-txt {
        border: none;
        background: none;
        outline: none;
        padding: 10px;
        color: black;
        font-size: 16px;
        transition: width 0.4s ease-in;
        line-height: 35px;
        width: 150px;
        font-family: 'Pangolin', cursive;
        cursor: pointer;
    }

    .search-txt:focus {
        width: 150px;
        cursor: text;
    }
}