.nevHeader {
  height: 45px;
  list-style-type: none;
  float: left;
  margin: 10px;
  padding: 10px;
  line-height: 5px;
  border-radius: 7px;
  box-sizing: border-box;
  transition: all ease .8s;
  color: white;
  font-size: 18px;
  font-family: sofia;
  text-align: center;
  transform: translateY(15px);
}

/* .header_sticky {
  position: fixed;
  top: 0;
  z-index: 1;
} */