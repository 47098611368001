
  .popup-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .popup-background {
    background-color: white;
    /* display: flex; */
    flex-direction: column;
    align-items: center;
    width: 100%;
    width: 600px;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.9);
    position: relative;
  }

  .pop {
    background: none;
    border: none;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
  }


  .close-icon {
    font-size: 24px;
  }
