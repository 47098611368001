.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.side1 {
  position: absolute;
  top: 250px;
  right: 40px;
  cursor: pointer;
}

.side2 {
  position: absolute;
  top: 250px;
  right: 40px;
  cursor: pointer;
}


@media (min-width: 360px) {
  .side2 {
    top: 290px;

  }

  .side1 {
    top: 290px;

  }

}

@media (min-width: 580px) and (max-width: 760px) {
  .side2 {
    top: 250px;

  }

  .side1 {
    top: 250px;

  }

}

@media (min-width: 610px) and (max-width: 760px) {
  .side2 {
    top: 250px;

  }

  .side1 {
    top: 250px;

  }

}

@media (min-width: 761px) {
  .side2 {
    top: 250px;

  }

  .side1 {
    top: 250px;

  }

}


.gwQnzd {
  margin-top: 118px;
}


.Lipo:hover {
  font-family: Cooper Black;
}

.icne {
  height: 35px;
  border-color: aliceblue;
  background-color: aliceblue;
  color: rgb(8, 112, 231);
  border-radius: 100%;
  width: 34px;
  padding: 5px;
  border: rgb(8, 112, 231);
}



/* Globle Css */

.background_Color {
  background-color: #173a9b;
}

.text_Color {
  color: white;
}

.para_font_family {
  font-family: "Unna", serif;
  font-size: 18px;
}

.heading_font {
  font-family: "DM Serif Text", serif;
  font-size: 22px;
  font-style: italic;
}

@media (max-width: 1366px) {
  .card_box {
    min-height: 260px;

  }
}

.card_box {
  min-height: 200px;
}

.card_box:hover .card {
  transition: 1s, transform 1s;
  transform: translateY(-4px);
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);

}

.text_color_heading {
  color: #173a9b;
}

.text_family_about {
  font-family: "DM Serif Text", serif;
  font-style: italic;
}


.image-style-align-center {
  display: block;
  height: auto;
  margin: 0 auto;
  text-align: center;
  width: fit-content;
  border: 1px solid #ccc;
}

.image-style-align-right {
  float: right;
  width: fit-content;
}

.image-style-align-left {
  float: left;
  width: fit-content;
}

.replies_button {
  color: #173a9b;
  background-color: none;
  padding: 5px;
  height: 33px;
  width: 100px;
  line-height: 20px;
  border-radius: 8px;
  transform: translateY(-20px);
  border: none
}

.replies_button:hover {
  background-color: #173a9b;
  color: white;
  line-height: 20px;

}


figure {
  width: fit-content;
  margin: 0 auto;
  margin-bottom: 10px;
}

figure img {
  box-shadow: 0 19px 38px rgba(199, 196, 196, 0.3), 0 15px 12px rgba(182, 178, 178, 0.22);
}

figure figcaption {
  text-align: center;
  color: rgb(23, 58, 155);
  font-size: medium;
  font-weight: bold;
  text-transform: lowercase;
  font-style: italic;
  background: rgba(8, 8, 8, 0.22);
  background: transparent;
  border-top: 1px solid #ccc;

}

.code-container {
  position: "relative";
  overflow-x: "auto";
  white-space: "pre-wrap";
  word-break: "break-word";
  border: "1px solid #dddddd";
  border-radius: "4px";
  margin: "8px 0";
  padding: "10px";
  background-color: "#f5f5f5";
  font-family: "monospace";
  font-size: "14px";
  line-height: "1.5";
  max-width: "100%";
}

pre {
  white-space: "pre-wrap";
  word-break: "break-word";
  overflow-x: "auto";
  margin: "0";
  padding: "0";
  background-color: "transparent";
}

code {
  white-space: "pre-wrap";
  word-break: "break-word";
  overflow-x: "auto";
}

.tag-button {
  background-color: #eef0f2;
  border: none;
  border-radius: 5px;
  padding: 3px 5px;
  transition: transform 0.2s ease, background-color 0.3s ease;
  box-shadow: 1px 2px 3px #6e7a9b;
}

.tag-button:hover {
  transform: translateY(-0.5px);
}

.tag-link {
  text-decoration: none;
  color: #173a9b;
  font-size: 14px;
  font-family: Arial, sans-serif;
}

.tag-link:hover {
  text-decoration: none;
}


pre {
  white-space: pre-wrap !important;
  word-break: break-word !important;
  overflow-x: auto !important;
}

code {
  white-space: pre-wrap !important;
  word-break: break-word !important;
}